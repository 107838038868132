// Import the functions you need from the SDKs you need
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDR1bcZpiCikYEEAx1T8kw4emKn9GBzl2w",
  authDomain: "teach-league.firebaseapp.com",
  projectId: "teach-league",
  storageBucket: "teach-league.appspot.com",
  messagingSenderId: "824406673244",
  appId: "1:824406673244:web:b8991c8c2dbf17679ba399",
  measurementId: "G-N25E0RP7QE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);