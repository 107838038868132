export const shoutOuts = [
  {
    id: 1,
    to: "Kyla",
    from: "Eitan",
    because: "Organizing laptops",
  },
  {
    id: 2,
    to: "Culinary and Sharon",
    from: "",
    because: "Food",
  },
  {
    id: 3,
    to: "Jill",
    from: "",
    because: "All her hard work",
  },
  {
    id: 4,
    to: "Anyone who had a hard day",
    from: "",
    because: "",
  },
  {
    id: 5,
    to: "Adrian",
    from: "",
    because: "Being our photographer",
  },
  {
    id: 6,
    to: "Vaj's Class",
    from: "Vaj",
    because: "For working out in the heat",
  },
  {
    id: 7,
    to: "Feminist Voices & Lives and Letters class",
    from: "",
    because: "Working hard",
  },
  {
    id: 8,
    to: "Sharon",
    from: "",
    because: "Food",
  },
  {
    id: 9,
    to: "Frangie",
    from: "",
    because: "Being here",
  },
  {
    id: 10,
    to: "Sarah",
    from: "",
    because: "Helping with schedules",
  },
  {
    id: 11,
    to: "Vaj",
    from: "Xander",
    because: "Teaching interesting classes",
  },
  {
    id: 12,
    to: "Kiri",
    from: "",
    because: "Taking care of us",
  },
  {
    id: 13,
    to: "New Teachers",
    from: "",
    because: "Working hard",
  },
  {
    id: 14,
    to: "Everyone who has been understanding",
    from: "",
    because: "",
  },
  {
    id: 15,
    to: "Everyone who helped make the building nice",
    from: "",
    because: "",
  },
  {
    id: 16,
    to: "Guy Painting",
    from: "Jude",
    because: "Painting",
  },
  {
    id: 17,
    to: "Ariis",
    from: "Jill",
    because: "Replacing water jug",
  },
  {
    id: 18,
    to: "Jack",
    from: "Hunter",
    because: "Killing it in music",
  },
  {
    id: 19,
    to: "Community Service + Jude & Xander",
    from: "",
    because: "Building lockers",
  },
  {
    id: 20,
    to: "Guy Hunter Vaj",
    from: "Jill",
    because: "Moving stuff",
  },
  {
    id: 21,
    to: "Michelle Mullin",
    from: "Xander",
    because: "Fun class",
  },
  {
    id: 22,
    to: "Eitan",
    from: "Ben",
    because: "Helping in class",
  },
  {
    id: 23,
    to: "Everyone who went to the creek",
    from: "",
    because: "",
  },
  {
    id: 24,
    to: "Deion",
    from: "",
    because: "Great class!",
  },
  {
    id: 25,
    to: "Hunter",
    from: "",
    because: "A fun geography class",
  },
  {
    id: 26,
    to: "Olivia and community Service",
    from: "",
    because: "Organizing the cold plunge and poster signing",
  },
  {
    id: 27,
    to: "Aimee",
    from: "",
    because: "Organizing the cold plunge and poster signing",
  },
  {
    id: 28,
    to: "Maddy and Andy",
    from: "Community Service Class",
    because:
      "Creating signs for rooms, creating a suggestion box, and planning Spirirt Week.",
  },
  {
    id: 29,
    to: "Xander and Simon",
    from: "Jill",
    because: "Helping out with the flags.",
  },
  {
    id: 30,
    to: "Zoe",
    from: "Kiri",
    because: "Sharing her fidget toys from home.",
  },
  {
    id: 31,
    to: "Eitan",
    from: "Kyla",
    because: "Taking the class outside to learn about Boulder history.",
  },
  {
    id: 32,
    to: "Middle School",
    from: "David",
    because: "Working hard on their research projects.",
  },
  {
    id: 33,
    to: "Deion",
    from: "Xander",
    because: "Helping him understand distance formulas.",
  },
  {
    id: 34,
    to: "Nyx",
    from: "Eitan",
    because: "Staying after class to get the class notes.",
  },
  {
    id: 35,
    to: "Deion",
    from: "Maddy",
    because: "Helping the class understand distance formulas.",
  },
  {
    id: 36,
    to: "Jill",
    from: "Eitan",
    because: "Fixing the printer",
  },
  {
    id: 37,
    to: "Music Classes",
    from: "Hunter",
    because: "Working hard and jamming out.",
  },
  {
    id: 38,
    to: "Survive the World + Community Service + Culinary",
    from: "Eitan",
    because: "Planning amazing meals.",
  },
  {
    id: 39,
    to: "Mamie Kakes",
    from: "Nova",
    because: "A fun community filled with dancing!",
  },
  {
    id: 40,
    to: "Aaron",
    from: "Jack",
    because: "Buying treats at Lolita's",
  },
  {
    id: 41,
    to: "Period 1B",
    from: "Vaj",
    because: "Helping prepare supplies for camping trip.",
  },
  {
    id: 42,
    to: "Aaron",
    from: "Jack",
    because: "Buying treats at Lolita's",
  },
  {
    id: 43,
    to: "Culinary",
    from: "Sarah",
    because: "Preparing pasta salad for fall trip.",
  },
  {
    id: 44,
    to: "Culinary",
    from: "Ari",
    because: "Making food",
  },
  {
    id: 45,
    to: "Eitan",
    from: "Kyla",
    because: "Fun class",
  },
  {
    id: 46,
    to: "Aimee",
    from: "Zoe",
    because: "Helping students feel safe to be vulnerable in class.",
  },
  {
    id: 47,
    to: "Student Drivers",
    from: "Merz",
    because: "Great driving!",
  },
  {
    id: 48,
    to: "Charlotte",
    from: "Olivia",
    because: "Gave your chipotle bowl",
  },
  {
    id: 49,
    to: "Cat",
    from: "David",
    because: "Great art presentation",
  },
  {
    id: 50,
    to: "Aimee",
    from: "Kyla",
    because: "Great class!",
  },
  {
    id: 51,
    to: "Students",
    from: "Vaj",
    because: "clearing out debris",
  },
  {
    id: 52,
    to: "Aimee",
    from: "Zoe",
    because: "Helping students feel safe to be vulnerable in class.",
  },
  {
    id: 53,
    to: "Jack",
    from: "Hunter",
    because: "Great presentation in music!",
  },
  {
    id: 54,
    to: "Olivia",
    from: "Tati",
    because: "Giving me a ride to school.",
  },
  {
    id: 54,
    to: "Xander",
    // from: "",
    because: "Giving me a ride to school.",
  },
  {
    id: 55,
    to: "Olivia",
    from: "Tati",
    because: "Giving me a ride to school.",
  },
  {
    id: 56,
    to: "Vaj and David",
    from: "Eitan",
    because: "Leading an awesome community.",
  },
  {
    id: 57,
    to: "Olivia",
    from: "Charlotte",
    because: "Buying me tacos.",
  },
  {
    id: 58,
    to: "Frangie & Barley",
    from: "Graysin",
    because: "",
  },
  {
    id: 59,
    to: "Olivia",
    from: "Kyla",
    because: "Getting a model for our art class",
  },
  {
    id: 60,
    to: "Adrien, O, & Cat",
    from: "David",
    because: "Great art class presentations",
  },
  {
    id: 61,
    to: "Tania",
    from: "Xander",
    because: "Giving us artistic freedom",
  },
  {
    id: 62,
    to: "",
    from: "",
    because: "Shout out to everyone participating in spirit week",
  },
  {
    id: 63,
    to: "Aimee",
    from: "Nichols",
    because: "Great book in class",
  },
  {
    id: 64,
    to: "Trevor & Nichols",
    from: "Sarah",
    because: "Being supportive friends to Ben",
  },
  {
    id: 65,
    to: "Charlie",
    from: "Michelle Mullin",
    because:"Charlie, you have grown so much since you have come to Sept School and I am just in awe of your vulnerability, kindness, and curiosity. Thanks for joining our community, Charlie!  You have absolutely made it better place.",
  },
];
